/* eslint-disable react/jsx-indent */
import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { useIntl } from 'gatsby-plugin-react-intl'
import 'react-vertical-timeline-component/style.min.css'
// import Bounce from 'react-reveal/Bounce'
import Layout from '../components/Layout'
import trackAndFieldIcon from '../img/trackAndFieldIcon.png'

const contentLukasz = [
  {
    year: 2015,
    pl:
      'Halowe Mistrzostwa Europy Masters w Toruniu, złoty medal w skoku w dal (kat. M35)',
    eng:
      'European Masters Indoor Championships in Toruń, gold medal in long jump (M35)',
  },
  {
    year: 2017,
    pl:
      'Halowe Mistrzostwa Świata Masters w Daegu, brązowy medal w skoku w dal i w trójskoku (M35)',
    eng:
      'World Masters Indoor Championships in Daegu, bronze medals in long jump and triple jump (M35)',
  },
  {
    year: 2019,
    pl:
      'Halowe Mistrzostwa Świata Masters w Toruniu, brązowy medal w trójskoku (M40)',
    eng:
      'World Masters Indoor Championships in Toruń, bronze medal in triple jump (M35)',
  },
  {
    year: 2019,
    pl: 'Mistrzostwa Europy Masters w Wenecji, brązowy medal w trójskoku (M40)',
    eng:
      'European Masters Championships in Venice, bronze medals in triple jump (M35)',
  },
]

function AboutAneta() {
  const iconRun = <img src={trackAndFieldIcon} width="80%" alt="" />
  const visibilitySetting = {
    default: { partialVisibility: false, offset: { top: 80 } },
  }
  const intl = useIntl()

  return (
    <Layout>
      <section className="section-bubble4">
        <div className="container">
          <h1
            className="title rainbow-underline p-3"
            style={{ paddingTop: '1em' }}
          >
            {intl.messages.aboutMe}
          </h1>
          <VerticalTimeline>
            {contentLukasz.map((yearContent, i) => {
              return (
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={yearContent.year}
                  iconStyle={{
                    background: 'rgb(33, 150, 243)',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  icon={iconRun}
                  key={`timeline-el-${i}`}
                >
                  <p>
                    {intl.locale === 'pl' ? yearContent.pl : yearContent.eng}
                  </p>
                </VerticalTimelineElement>
              )
            })}

            <VerticalTimelineElement
              iconStyle={{
                background: 'rgb(16, 204, 82)',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={iconRun}
              visibilitySensorProps={visibilitySetting}
            />
          </VerticalTimeline>
        </div>
      </section>
    </Layout>
  )
}

export default AboutAneta
